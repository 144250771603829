import { z } from "zod";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "~/server/api/root";
import type { OrganizationRole } from "@prisma/client";
import { api } from "~/utils/api";
import { getIntegrationSetup } from "~/server/api/routers/integrations/crud-integration";

type RouterInput = inferRouterInputs<AppRouter>;
type RouterOutput = inferRouterOutputs<AppRouter>;

export type CustomerShort =
  RouterOutput["customer"]["getCustomers"]["items"][0];
export type Customer = RouterOutput["customer"]["getCustomer"];
export type Deal = RouterOutput["deal"]["getDeals"]["0"];
export type DealLine = RouterOutput["deal"]["getDeals"]["0"]["dealLine"][0];
export type Invoice = RouterOutput["invoice"]["getInvoice"];
export type CustomersInvoices =
  RouterOutput["customer"]["getCustomersInvoices"]["items"]["0"];
export type TeamMember = RouterOutput["organization"]["getTeamMembers"][0];
export type OrganizationSettings =
  RouterOutput["organization"]["getOrganization"];
export type Product = RouterOutput["product"]["getProduct"];
export type ChangeLogRecord = RouterOutput["misc"]["changeLog"][0];
export type IssuesRecord = RouterOutput["integration"]["getIssues"][0];
export type StagedData =
  RouterOutput["integration"]["getStagedData"]["items"][0];
export type MissingRecordsData =
  RouterOutput["integration"]["getMissingRecordsData"]["items"][0];
export type DuplicateData =
  RouterOutput["integration"]["getDuplicateData"]["items"][0];
export type IntegrationSetupData =
  RouterOutput["integration"]["getIntegrationSetup"];
export type IntegrationInfoData =
  RouterOutput["integration"]["getIntegrationInfo"];
export type Notifications =
  RouterOutput["notifications"]["getNotifications"]["items"][0];

export interface UserProfile {
  id: string;
  email: string;
  name?: string | null;
  phone?: string | null;
  // agreeToTerms?: Date | null;
  role: "ADMIN" | "USER";
  avatarImage?: string | null;
  organizationRole: OrganizationRole;
  organizationId: string;
  organizationName: string;
  organizationMember: {
    id: string;
    name: string;
    logo: string | null;
  }[];
}

export type PartialNullable<T> = {
  [P in keyof T]?: T[P] | null;
};

export enum PaymentMethod {
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH = "CASH",
  CREDIT_CARD = "CREDIT_CARD",
  PAYPAL = "PAYPAL",
}

export const sortingState = z.array(
  z.object({
    desc: z.boolean(),
    id: z.string(),
  }),
);

export const TableParams = z.object({
  pagination: z.object({
    pageIndex: z.number(),
    pageSize: z.number(),
  }),
  sortInfo: sortingState,
});

export type TableParams = z.infer<typeof TableParams>;

export const NotificationStatus = z.enum(["active", "hidden"]);
export type NotificationStatus = z.infer<typeof NotificationStatus>;

// `${ObjectIntegrationInfoDuplicate}${objectIntegrationInfoId}:${cube9ObjectId}`,
// `${ObjectIntegrationInfoPending}Y:[${cube9Object.id}]`,
export const ObjectIntegrationInfoDuplicate = "duplicate:" as const;
export const ObjectIntegrationInfoPending = "pending:" as const;
export const ObjectIntegrationInfoLocked = "lock:" as const;
export const ObjectIntegrationInfoError = "error:" as const;

export const objectIntegrationInfoStatus = (
  objectIntegrationInfo: string | undefined,
): "none" | "staged" | "pre-cond" | "duplicate" | "linked" | "error" => {
  if (!objectIntegrationInfo) {
    return "none";
  }

  if (objectIntegrationInfo?.startsWith(`${ObjectIntegrationInfoPending}Y:`)) {
    return "staged";
  } else if (
    objectIntegrationInfo?.startsWith(`${ObjectIntegrationInfoPending}N:`)
  ) {
    return "pre-cond";
  } else if (
    objectIntegrationInfo?.startsWith(ObjectIntegrationInfoDuplicate)
  ) {
    return "duplicate";
  } else if (objectIntegrationInfo?.startsWith(ObjectIntegrationInfoError)) {
    return "error";
  } else if (objectIntegrationInfo === "UNLINKED") {
    return "error";
  } else {
    return "linked";
  }
};
